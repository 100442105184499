.logs_page {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xxx-big);
    align-items: center;

    .logs_header {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-xx-sm);
        align-items: center;

        .MuiTypography-h1 {
            font-size: var(--t-desktop-headings-h1);
            font-weight: 400;
            line-height: 40px;
            color: var(--c-gray-900);
        }

        .MuiTypography-body1 {
            font-size: var(--t-desktop-paragraph-normal);
            font-weight: 400;
            line-height: 24px;
            color: var(--c-gray-900);
        }
    }

    .all_logs {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-xx-sm);
        width: 100%;

        .log_entry {
            background-color: white;
            padding: var(--spacing-sm);
            border: 1px solid var(--c-gray-200);
            border-radius: var(--border-radius-medium);
            display: flex;
            flex-direction: column;
            gap: var(--spacing-xxx-sm);

            .log_header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            ul {
                margin: 0;
                padding: 0 var(--spacing-sm);
            }
        }
    }
}
