button.MuiButton-root.btn_back {
    padding: var(--spacing-x-sm);
    display: flex;
    align-items: center;
    gap: var(--spacing-xxx-sm);

    .MuiIcon-root {
        font-size: var(--t-button-normal);
    }

    .btn_back_text {
        font-size: var(--t-button-small);
        font-weight: 400;
        line-height: 20px;
    }
}
