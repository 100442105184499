.chart-container {
    .container-title {
        border-top-left-radius: var(--border-radius-small);
        border-top-right-radius: var(--border-radius-small);
        background-color: var(--c-primary-hover);
        padding: var(--spacing-x-sm) var(--spacing-sm);

        .MuiTypography-h4 {
            font-size: var(--fs-px-h4);
            font-weight: 600;
            line-height: 28px;
            color: var(--c-gray-900);
            text-align: center;
        }
    }

    .charts {
        background-color: white;
        // padding: var(--spacing-x-sm) var(--spacing-sm);
        // border-bottom: 1px solid var(--c-gray-300);
        border-bottom-left-radius: var(--border-radius-small);
        border-bottom-right-radius: var(--border-radius-small);
    }
}
