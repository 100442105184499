.status-header {
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--spacing-x-big);
    padding: var(--spacing-xxx-big);
    border-radius: var(--border-radius-small);

    .status-info {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: var(--spacing-x-sm);

        .status-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: var(--spacing-xxx-sm);
            text-align: center;

            .MuiIcon-root {
                font-size: 56px;

                &.ok {
                    color: var(--c-success);
                }
                &.info {
                    color: var(--c-info-dark);
                }
                &.warning {
                    color: var(--c-warning);
                }
                &.error {
                    color: var(--c-error);
                }
            }

            .MuiTypography-h1 {
                font-size: var(--t-desktop-headings-h1);
                font-weight: 400;
                line-height: 40px;
                color: var(--c-gray-900);
            }

            .MuiTypography-subtitle1 {
                font-size: var(--t-desktop-paragraph-normal);
                font-weight: 400;
                line-height: 24px;
                color: var(--c-gray-800);
            }
        }

        .MuiTypography-subtitle2 {
            font-size: var(--t-desktop-paragraph-normal);
            font-weight: 400;
            line-height: 24px;
            color: var(--c-gray-600);
        }
    }

    .status-buttons {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: var(--spacing-xxx-sm);

        .MuiIcon-root {
            font-size: var(--t-button-normal);
        }
    }
}
