.footer {
    background-color: var(--c-primary);
    padding: var(--spacing-xxx-big);

    .footer-content {
        margin: 0 auto;
        max-width: 850px;
        align-items: center;
        flex-wrap: wrap;
        gap: var(--spacing-sm);

        @media (width < 680px) {
            justify-content: center;
        }

        .center {
            align-items: center;

            .copyright {
                color: white;
                font-size: var(--fs-px-body2);
                font-weight: 400;
                line-height: 20px;
                text-align: center;

                a {
                    color: white;
                }
            }
        }

        .social {
            display: flex;
            gap: var(--spacing-xx-sm);

            .social-icon {
                background-color: white;
                color: var(--c-primary);
                width: 20px;
                height: 20px;

                .MuiSvgIcon-root {
                    font-size: var(--fs-px-body2);
                }
            }
        }
    }
}
