// @use "mixins" as *;
@use "sass:math";

$base-font-size: 14 !default;

:root {
    --c-primary-dark: #0026ca;
    --c-primary: #304ffe;
    --c-primary-light: #7a7cff;

    --c-secondary-dark: #546e7a;
    --c-secondary: #cfd8dc;
    --c-secondary-light: #eceff0;

    --c-highlight-dark: #c60c3e;
    --c-highlight: #ff5268;
    --c-highlight-light: #fa8695;

    --c-gray-darker: #606060;
    --c-gray-dark: #9b9b9b;
    --c-gray: #c8c4c4;
    --c-gray-light: #d8d8d8;
    --c-gray-lighter: #e4e5e6;
    --c-gray-lighter-v2: #f5f5f5;

    --c-success: #51c03e;
    --c-warning: #fca622;
    --c-error: #d0021b;

    --fs-h1: #{math.div(34, $base-font-size)}rem;
    --fs-h2: #{math.div(28, $base-font-size)}rem;
    --fs-h3: #{math.div(24, $base-font-size)}rem;
    --fs-h4: #{math.div(18, $base-font-size)}rem;
    --fs-body1: #{math.div(16, $base-font-size)}rem;
    --fs-body2: #{math.div(14, $base-font-size)}rem;
    --fs-button: #{math.div(12, $base-font-size)}rem;
    --fs-table: #{math.div(12, $base-font-size)}rem;
    --fs-others: #{math.div(10, $base-font-size)}rem;

    --fs-px-h1: 34px;
    --fs-px-h2: 28px;
    --fs-px-h3: 24px;
    --fs-px-h4: 18px;
    --fs-px-body1: 16px;
    --fs-px-body2: 14px;
    --fs-px-button: 12px;
    --fs-px-table: 12px;
    --fs-px-others: 10px;
}

:root {
    // TYPOGRAPHY

    --t-desktop-headings-large: 40px;
    --t-desktop-headings-small: 36px;
    --t-desktop-headings-h1: 32px;
    --t-desktop-headings-h2: 28px;
    --t-desktop-headings-h3: 24px;
    --t-desktop-headings-h4: 20px;
    --t-desktop-headings-h5: 18px;
    --t-desktop-headings-h6: 16px;

    --t-desktop-paragraph-large: 18px;
    --t-desktop-paragraph-normal: 16px;
    --t-desktop-paragraph-small: 14px;
    --t-desktop-paragraph-xsmall: 12px;

    --t-mobile-headings-h1: 28px;
    --t-mobile-headings-h2: 24px;
    --t-mobile-headings-h3: 20px;
    --t-mobile-headings-h4: 18px;
    --t-mobile-headings-h5: 16px;
    --t-mobile-headings-h6: 14px;

    --t-mobile-paragraph-large: 16px;
    --t-mobile-paragraph-normal: 14px;
    --t-mobile-paragraph-small: 12px;

    --t-input: 16px;
    --t-input-number: 24px;
    --t-form-control-large: 18px;
    --t-form-control-small: 14px;
    --t-hint: 12px;

    --t-button-xsmall: 12px;
    --t-button-small: 14px;
    --t-button-normal: 16px;
    --t-button-large: 18px;

    // FONT WEIGHTS

    --fw-bold: 700;
    --fw-semibold: 600;
    --fw-regular: 400;
    --fw-light: 300;

    // COLORS
    --c-primary: #304ffe;
    --c-primary-dark: #0026ca;
    --c-primary-light: #7a7cff;
    --c-primary-ultra-light: #e6eaff;
    --c-primary-hover: #edeef5;

    --c-black: #000;
    --c-gray-900: #212121;
    --c-gray-800: #424242;
    --c-gray-600: #757575;
    --c-gray-500: #9e9e9e;
    --c-gray-400: #bdbdbd;
    --c-gray-300: #e0e0e0;
    --c-gray-100: #f5f5f5;
    --c-white: #fff;

    --c-primary-gradient: linear-gradient(rgba(1, 175, 230, 1), rgba(44, 85, 252, 1));

    --c-bot: #5f55ff;
    --c-user: #4ab74a;
    --c-action: #ff7121;
    --c-condition: #f6a440;

    --c-error: #fb1531;
    --c-error-dark: #d0021b;
    --c-error-light: #fae6e8;
    --c-error-hover: #ffdce0;

    --c-success: #02c66a;
    --c-success-dark: #00875a;
    --c-success-light: #e6f9f0;
    --c-success-hover: #d7f7e8;

    --c-warning: #f5a623;
    --c-warning-dark: #e4931a;
    --c-warning-light: #fff9e5;
    --c-warning-hover: #fff6d6;

    --c-info: #304ffe;
    --c-info-dark: #0026ca;
    --c-info-light: #e6eaff;
    --c-info-hover: #d6dcff;

    --c-cyan: #00bcd4;
    --c-purple: #9c27b0;
    --c-orange: #ec675d;
    --c-green-apple: #8bc34a;
    --c-yellow: #edba1e;
    --c-dark-green: #068d80;
    --c-blue: #203bd1;
    --c-pink: #ff3c9a;
    --c-violet: #673ab7;
    --c-green: #3db142;
    --c-sky-blue: #3199eb;
    --c-dark-yellow: #f49305;
    --c-gray: #607d8b;
    --c-brown: #795548;

    // SPACING
    --spacing-xxx-sm: 4px;
    --spacing-xx-sm: 8px;
    --spacing-x-sm: 12px;
    --spacing-sm: 16px;

    --spacing-big: 20px;
    //  --spacing-x-big = spacing-md in figma
    --spacing-x-big: 24px;
    --spacing-xx-big: 28px;
    --spacing-xxx-big: 32px;

    --spacing-lg: 40px;
    --spacing-x-lg: 48px;
    --spacing-xx-lg: 64px;
    --spacing-xxx-lg: 80px;

    --spacing-huge: 96px;
    --spacing-x-huge: 128px;
    --spacing-xx-huge: 160px;
    --spacing-xxx-huge: 192px;

    // SHADOWS
    --shadow-bottom-small: 0px 2px 5px 0px #0000001f;
    --shadow-bottom-medium: 0px 5px 15px 0px #0000001f;
    --shadow-bottom-large: 0px 5px 15px 0px #00000026;

    --shadow-gray-small: 0px 0px 3px 0px #82828226;
    --shadow-gray-medium: 0px 0px 7px 0px #82828226;
    --shadow-gray-large: 0px 0px 15px 0px #82828226;

    //BORDER RADIUS
    --border-radius-small: 4px;
    --border-radius-medium: 8px;
}

::-webkit-scrollbar-track {
    background-color: hsla(0, 0%, 0%, 0.1);
    border: 4px solid transparent;
    background-clip: content-box;
    border-radius: 6px;
}

::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--c-gray-dark);
    border-radius: 6px;
    border: 4px solid transparent;
    background-clip: content-box;
    height: 56px;
    width: 56px;
}

html,
body {
    // background-color: transparent !important;
    background-color: #f5f5f5 !important;
    font-size: #{$base-font-size}px;
    min-width: 320px;
    overflow-y: hidden;
}

body {
    margin: 0;
    overflow: overlay;
}

button,
a {
    &.MuiButton-root {
        border-radius: 16px;
        font-size: var(--fs-button);
        line-height: 1;
        text-transform: unset;
        white-space: nowrap;
        font-weight: 400;
        height: 32px;
        padding-left: 16px;
        padding-right: 16px;
    }
    &.MuiButton-contained {
        &,
        &:hover,
        &:active {
            box-shadow: none;
        }
    }
    &.MuiButton-outlined,
    &.MuiButton-text {
        font-weight: 500;
    }
    &.MuiButton-sizeLarge {
        font-size: var(--fs-body2);
        height: 38px;
        border-radius: 19px;
        padding-left: 20px;
        padding-right: 20px;
    }

    &.MuiButton-containedPrimary {
        background-color: var(--c-primary);
        &:hover {
            background-color: var(--c-primary-dark);
        }
    }
    &.MuiButton-containedSecondary {
        background-color: var(--c-highlight);
        &:hover {
            background-color: var(--c-highlight-dark);
        }
    }
    &.MuiButton-outlinedPrimary {
        color: var(--c-primary);
        border: 1px solid var(--c-primary-light);
        &:hover {
            border: 1px solid var(--c-primary-dark);
        }
    }
    &.MuiButton-outlinedSecondary {
        color: var(--c-highlight);
        border: 1px solid var(--c-highlight-light);
        &:hover {
            border: 1px solid var(--c-highlight-dark);
        }
    }
    &.MuiButton-textPrimary {
        color: var(--c-primary);
    }
    &.MuiButton-textSecondary {
        color: var(--c-highlight);
    }
}

#click-to-component-menu#click-to-component-menu {
    z-index: 10000 !important;
}
