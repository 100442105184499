.uptime-chart-container {
    display: flex;
    flex-direction: column;
    height: 87px;
    width: 100%;

    &.height-100 {
        height: 100%;
    }

    .custom-x-axis-binary {
        display: flex;
        justify-content: space-between;

        .MuiTypography-root {
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
        }
    }
}

.x-axis-uptime {
    font-weight: 400;
    font-size: var(--t-mobile-paragraph-small);
    line-height: 17px;
}

.binaryBarTooltip {
    background-color: white;
    border: 1px solid var(--c-dark);
    padding: var(--spacing-xx-sm) var(--spacing-x-sm);
    border-radius: var(--border-radius-small);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    gap: var(--spacing-sm);
    color: var(--c-gray-900);

    .MuiTypography-body2 {
        font-weight: 600 !important;
    }

    .date {
        font-size: var(--t-desktop-paragraph-small);
        line-height: 20px;
        font-weight: 400;
        text-wrap: nowrap;

        span {
            color: var(--c-gray-600);
        }
    }
}
